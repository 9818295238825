@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    background-color: #212121;
    width: 100%;
    height: 100%;
}

.diagram-component {
    width: 100%;
    height: 92vh;
    background-color: rgb(41, 41, 41);
    /* flex-basis: 80%; */
    margin-left: 0.5rem;
}

.inspector {
    display: inline-block;
    font: bold 14px helvetica, sans-serif;
    background-color: #212121; /* Grey 900 */
    color: #f5f5f5; /* Grey 100 */
    cursor: default;
}

    .inspector table {
        border-collapse: separate;
        border-spacing: 2px;
    }

    .inspector td,
    th {
        padding: 2px;
    }

    .inspector input {
        background-color: #424242; /* Grey 800 */
        color: #f5f5f5; /* Grey 100 */
        font: bold 12px helvetica, sans-serif;
        border: 0px;
        padding: 2px;
    }

        .inspector input:disabled {
            background-color: #bdbdbd; /* Grey 400 */
            color: #616161; /* Grey 700 */
        }

    .inspector select {
        background-color: #424242;
    }

::-webkit-scrollbar {
    width: 1em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: rgb(66, 66, 66);;
    outline: 1px solid rgb(66, 66, 66);;
}

.webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
    background-color: rgb(127, 133, 245);
    outline: 1px solid rgb(127, 133, 245);
}

.webchat__basic-transcript__scrollable::-webkit-scrollbar {
    width: 2px
}

.loader {
    border-top-color: rgb(127, 133, 245);
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.accessDenied {
    color: #FFFFFF;
    padding: 32px;
}