@media (prefers-color-scheme: light) {
  .webchat__main {
    border-color: #191919;
    background-color: #f2f2f2;
  }

  .webchat__send-box__main {
    border-top: #ffffff 1px solid !important;
    font-weight: 300;
    padding-right: 4px;
    border-radius: 8px;
    background-color: #ffffff !important;
    height: 45px;
    font-size: 15px;
  }

  #webchat > div {
    background-color: #f2f2f2 !important;
  }

  .webchat__send-icon {
    color: #dcdcdc;
  }

  .webchat__send-box {
    border-radius: 10px;
    background-color: #ffffff !important;
  }

  .webchat__icon-wrapper {
    position: absolute;
    z-index: 40;
    bottom: 16px;
    right: 24px;
    font-size: 22px;
    cursor: pointer;
    color: #7b83eb;
  }

  .cs-button--attachment {
    display: none !important;
  }

  .webchat-dev__icon-wrapper {
    position: fixed;
    z-index: 40;
    bottom: 16px;
    right: 24px;
    font-size: 22px;
    cursor: pointer;
    color: #7b83eb;
  }

  .webchat-dev__wrapper {
    position: absolute;
    z-index: 40;
    bottom: 50px;
    right: 24px;
    font-size: 22px;
    height: 350px;
    width: 325px;
  }

  .webchat__header {
    font-size: 24px;
  }

  .webchat__header .webchat__icon {
    margin: 0 4px 0 8px;
    color: #7b83eb !important;
    cursor: pointer;
  }

  #webchat > * {
    font-size: 14px;
  }

  .webchat__icon-button {
    fill: #7b83eb !important;
  }

  .webchat__icon-button:hover {
    fill: #7f85f5 !important;
  }

  .webchat__icon-button__shade {
    display: none;
  }

  .webchat__send-box-text-box__input {
    color: #000000 !important;
  }

  .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content {
    background: #ffffff !important;
  }

  .webchat__bubble__content {
    border-radius: 6px !important;
    min-height: 30px !important;
  }

  .webchat__bubble__content .markdown,
  .plain {
    padding: 6px !important;
    font-size: 15px !important;
  }

  .webchat__imageAvatar,
  .webchat__defaultAvatar,
  .webchat__initialsAvatar {
    height: 33px !important;
    width: 33px !important;
  }

  .webchat__stacked-layout__avatar-gutter {
    width: 33px !important;
  }

  .webchat__initialsAvatar__initials {
    font-size: 14px !important;
  }

  .webchat__send-box-text-box {
    font-size: 13px;
    font-weight: 300;
    padding: 8px 4px 8px 6px;
    background-color: #ffffff;
    opacity: 1;
    color: #000000 !important;
    border-radius: 4px;
  }

  .webchat__icon:hover {
    color: #7f85f5 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .webchat__main {
    border-color: #191919;
    background-color: #000000;
  }

  .webchat__send-box__main {
    border-top: #000000 1px solid !important;
    font-weight: 300;
    padding-right: 4px;
    border-radius: 4px;
    background-color: #000000 !important;
  }

  .webchat__send-icon {
    color: #000000;
  }

  .webchat__send-box {
    background-color: #000000 !important;
  }

  .webchat__icon-wrapper {
    position: absolute;
    z-index: 40;
    bottom: 16px;
    right: 24px;
    font-size: 22px;
    cursor: pointer;
    color: #7b83eb;
  }

  .webchat__header {
    font-size: 24px;
  }

  .webchat__header .webchat__icon {
    margin: 0 4px 0 8px;
    color: #7b83eb !important;
    cursor: pointer;
  }

  #webchat > * {
    font-size: 14px;
  }

  .webchat__icon-button {
    fill: #7b83eb !important;
  }

  .webchat__icon-button:hover {
    fill: #7f85f5 !important;
  }

  .webchat__icon-button__shade {
    display: none;
  }

  .webchat__send-box-text-box__input {
    color: #ffffff !important;
  }

  .webchat__bubble__content {
    border-radius: 6px !important;
    min-height: 50px !important;
  }

  .webchat__bubble__content .markdown,
  .plain {
    padding: 6px !important;
  }

  .webchat__imageAvatar,
  .webchat__defaultAvatar,
  .webchat__initialsAvatar {
    height: 30px !important;
    width: 30px !important;
  }

  .webchat__stacked-layout__avatar-gutter {
    width: 30px !important;
  }

  .webchat__initialsAvatar__initials {
    font-size: 13px !important;
  }

  .webchat__send-box-text-box {
    font-size: 13px;
    font-weight: 300;
    padding: 8px 4px 8px 6px;
    background-color: #000000;
    opacity: 1;
    color: #ffffff !important;
    border-radius: 4px;
  }

  .webchat__icon:hover {
    color: #7f85f5 !important;
  }
}
