.commands-list {
    color: #FFFFFF;
    padding: 16px 0 0 16px;
    width: 70%;
}

.commands-list_children--closed {
    transition: all 1s -0.25s ease-out;
    overflow: hidden;
    max-height: 0;
    background-color: #FFFFFF;
    color: #000000;
    visibility: hidden;
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.commands-list_children--opened {
    background-color: #FFFFFF;
    color: #000000;
    max-height: 100px;
    animation: fadeIn 1.2s;
    padding: 8px 0;
    margin-top: 8px;
}


.commands-list_children {
    padding-left: 12px;
}

.commands-list__command {
    border-top-right-radius: 60px 30px;
    background-color: #7f85f5;
    cursor: pointer;
    padding: 12px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
}

.commands-list_children-name {
    font-size: 15px;
    font-weight: bold;
    color: #7f85f5;
}

.commands-list_children-description {
    font-size: 14px;
}